import {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButton,
    IonModal,
    IonIcon,
} from '@ionic/react';

import React, { useEffect, useState } from "react";
import Store from "../store";
import { getCurrentUser } from "../store/selectors";
import { Header } from "../components/Header";
import { deleteDocument, getUserDocs, saveDocument, updateDocument } from "../store/firebaseService";
import './UserRentals.module.css';
import styles from './UserRentals.module.css';
import { useHistory } from "react-router-dom";
import CardContainer from "../components/CardContainer";
import RentalWizard from "../components/RentalWizard"; // Assume this is the wizard component
import pubSub from "../events/PubSub";
import EventType from "../constants/EventType";
import {CardProps, Rental, RentalCreate} from "../typings/typings";
import { addSharp } from "ionicons/icons";

const UserRentals = () => {
    const [rentalItems, setRentalItems] = useState<CardProps[]>([]);
    const [showWizard, setShowWizard] = useState(false);
    const [currentRental, setCurrentRental] = useState<Rental | null>(null); // State for editing a rental
    const user = Store.useState(getCurrentUser);

    const fetchRentals = () => {
        if (user?.uid) {
            getUserDocs("rentals", user.uid).then((docs) => {
                let cards = docs.map((doc) => ({
                    id: doc.id,
                    title: doc.title,
                    description: doc.description,
                    handleOnClick: () => {
                        setCurrentRental(doc as Rental);
                        setShowWizard(true);
                    },
                    onEditHandler: () => {
                        setCurrentRental(doc as Rental);
                        setShowWizard(true);
                    },
                    onDeleteHandler: () => {
                        deleteDocument("rentals", doc.id)
                            .then(() => {
                                setRentalItems((prev) =>
                                    prev.filter((item) => item.id !== doc.id)
                                );
                            })
                            .catch((error) => {
                                pubSub.emit(
                                    EventType.TOAST_ERROR,
                                    "Error deleting rental: " + error
                                );
                            });
                    },
                }));
                setRentalItems(cards);
            });
        }
    };

    useEffect(() => {
        fetchRentals();
    }, []);

    const handleSaveRental = async (rentalData: Rental | RentalCreate) => {
        if (user?.uid) {
            try {
                if (currentRental?.id) {
                    // Update rental
                    await updateDocument("rentals", currentRental.id, rentalData);
                } else {
                    // Add new rental
                    await saveDocument("rentals", { ...rentalData, uid: user.uid });
                }
                fetchRentals(); // Refresh rental list
                setShowWizard(false);
                alert("Rental saved successfully!");
            } catch (error) {
                console.error("Error saving rental:", error);
                alert("Failed to save rental. Please try again.");
            }
        }
    };

    const openWizard = () => {
        setCurrentRental(null); // Clear current rental when adding new
        setShowWizard(true);
    };

    const closeWizard = () => {
        setShowWizard(false);
    };

    return (
        <IonPage>
            <Header
                text="Rentals"
                areNotificationsEnabled={false}
                setShowNotificationsProps={() => {}}
            />
            <IonContent className="ion-padding content-container" fullscreen>
                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large">Rentals</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonButton
                    style={{ margin: '0 34%', fontSize: '1.2rem' }}
                    expand="block"
                    onClick={openWizard}
                >
                    <IonIcon icon={addSharp}></IonIcon>
                    Add Rental
                </IonButton>
                <div>
                    <CardContainer cards={rentalItems}></CardContainer>

                    <IonModal
                        isOpen={showWizard}
                        onDidDismiss={closeWizard}
                        backdropDismiss={false}
                    >
                        <RentalWizard
                            onClose={closeWizard}
                            onSave={handleSaveRental} // Pass save handler
                            rental={currentRental}
                        />
                    </IonModal>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default UserRentals;
