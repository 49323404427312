import React from "react";
import {Route, Redirect} from "react-router-dom";
import Store from "../store";
import * as selectors from "../store/selectors";


interface ProtectedRouteProps {
    path: string;
    exact: boolean;
    children?: React.ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
                                                           path,
                                                           exact,
                                                           children,
                                                       }) => {
    const user = Store.useState(selectors.getCurrentUser);
    function getRoute() {
        return <>{user !== undefined ? children : <Redirect to="/begin"/>}</>;
    }

    return (
        <Route path={path} exact={exact}>
            {getRoute()}
        </Route>
    );
};

export default ProtectedRoute;
