import React, {Component} from "react";
import {IonButton, IonButtons, IonHeader, IonIcon, IonMenuButton, IonTitle, IonToolbar} from "@ionic/react";
import {notificationsOutline} from "ionicons/icons";

interface HeaderProps {

    setShowNotificationsProps: () => void;
    areNotificationsEnabled: boolean;
    text: string;

}

export class Header extends Component<HeaderProps> {

    render() {
        return (
            <IonHeader>
                <IonToolbar>
                    <IonTitle>{ this.props.text}</IonTitle>
                    <IonButtons slot="start">
                        <IonMenuButton/>
                    </IonButtons>
                    {this.props.areNotificationsEnabled &&
                        <IonButtons slot="end">
                            <IonButton onClick={() => this.props.setShowNotificationsProps}>
                                {/* eslint-disable-next-line react/jsx-no-undef */}
                                <IonIcon icon={notificationsOutline}/>
                            </IonButton>
                        </IonButtons>
                    }
                </IonToolbar>
            </IonHeader>
        )
    }
}
