import {
    IonAvatar,
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonMenu,
    IonMenuToggle,

} from '@ionic/react';

import {useLocation} from 'react-router-dom';
import {
    documents, documentsSharp,
    settingsSharp, settings, home, homeSharp, logOut, logOutSharp,
} from 'ionicons/icons';
import './Menu.css'
import React from "react";
import {WaveMenu, WaveMenuUp} from "./Wave";
import Store from "../store";
import * as selectors from "../store/selectors";
import {logOut as LogOutFn} from "../store/actions";
interface AppPage {
    url: string;
    iosIcon: string;
    mdIcon: string;
    title: string;
}

const appPages: AppPage[] = [
    {
        title: 'Slides',
        url: '/rentals',
        iosIcon: documents,
        mdIcon: documentsSharp
    },
    {
        title: 'Settings',
        url: '/settings',
        iosIcon: settings,
        mdIcon: settingsSharp
    }
]
const logOff: AppPage = {
    title: 'Logout',
    url: '/logout',
    iosIcon: logOut,
    mdIcon: logOutSharp
}

type ProfilePillProps = {
    name: string;
    email: string;

    profileUrl: string;
}

class ProfilePill extends React.Component<ProfilePillProps> {

    render() {
        return <div className="pill">
            <IonAvatar>
                <img alt="Silhouette of a person's head" src={this.props.profileUrl}/>
            </IonAvatar>
            <div>
                <h3>{this.props.name}</h3>
                <p>{this.props.email}</p>
            </div>
        </div>;
    }
}

const Menu: React.FC = () => {
    const user = Store.useState(selectors.getCurrentUser);
    return (
        user? <IonMenu className="menu" contentId="main">

            <WaveMenuUp></WaveMenuUp>

           <IonContent>
                <IonList id="menu-list">
                    <div className="logo-div">
                        <img alt={"Slideme Logo"}src="/img/icon.png"></img>
                        <IonListHeader>Slideme</IonListHeader>
                    </div>

                    <ProfilePill profileUrl={user?.photoURL || "https://ionicframework.com/docs/img/demos/avatar.svg"} name={user?.displayName || "undefined"} email={user?.email || "undefined"} key={1}/>
                    <div key={"list"} className={"menuitems"}>
                        {appPages.map((appPage, index) => {
                            return (
                                <IonMenuToggle key={index} autoHide={false}>
                                    <IonItem key={index}
                                             routerLink={appPage.url} routerDirection="none" lines="none"
                                             detail={false}>
                                        <IonIcon aria-hidden="true" slot="start" ios={appPage.iosIcon}
                                                 md={appPage.mdIcon}/>
                                        <IonLabel>{appPage.title}</IonLabel>
                                    </IonItem>
                                </IonMenuToggle>
                            );
                        })}

                    </div>

                </IonList>
                <IonList className="menu-list-2">
                    <div key={"list2"} className={"menuitems"}>
                        <IonMenuToggle key={"logofft"} autoHide={false}>
                            <IonItem key={"logoff"}
                                     routerLink={logOff.url} onClick={()=>{LogOutFn()}} routerDirection="none" lines="none"
                                     detail={false}>
                                <IonIcon aria-hidden="true" slot="start" ios={logOff.iosIcon}
                                         md={logOff.mdIcon}/>
                                <IonLabel>{logOff.title}</IonLabel>
                            </IonItem>
                        </IonMenuToggle>

                    </div>

                </IonList>
            </IonContent>
            <WaveMenu></WaveMenu>
        </IonMenu> : <div></div>

    );
};

export default Menu;
