import React, {useEffect, useState} from 'react';
import {
    IonApp, IonContent,
    IonHeader,
    IonMenu,
    IonRouterOutlet,
    IonSplitPane,
    IonTitle,
    IonToolbar,
    setupIonicReact
} from '@ionic/react';
import {IonReactRouter} from '@ionic/react-router';
import {Redirect, Route} from 'react-router-dom';
import Menu from './components/Menu';
import Feed from './pages/Feed';
import storageService from '../src/store/storage'; // Import the storage service
import {
    StatusBar,
    Style
} from '@capacitor/status-bar';

import 'tailwindcss/tailwind.css';
import '@ionic/react/css/core.css';
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

import './theme/global.css';
import './theme/variables.css';
import Settings from "./pages/Settings";
import Login from "./pages/Login";
import Home from "./pages/Home";
import Signup from "./pages/Signup";
import ProtectedRoute from "./components/ProtectedRoute";
import ToastContainer from "./components/shared/toast/ToastContainer";
import UserSummaries from "./pages/UserRentals";
import FrameComponent from "./components/FrameComponent";
import Store from "./store";
import {StoreProps} from "./typings/typings";
import CustomSidebar from "./components/Menu";
import UserRentals from "./pages/UserRentals";

setupIonicReact();

window.matchMedia("(prefers-color-scheme: dark)").addListener(async (status) => {
    try {
        await StatusBar.setStyle({
            style: status.matches ? Style.Dark : Style.Light,
        });
    } catch {
    }
});

const App: React.FC = () => {
    const [isStorageReady, setIsStorageReady] = useState(false);
    const [user, setUser] = useState(null);

    useEffect(() => {
        const initializeStorage = async () => {
            try {
                await storageService.init();
                const storedUser = await storageService.get('user');
                if (storedUser) {
                    console.log("User retrieved from storage");
                    setUser(storedUser);
                    Store.update((s: StoreProps) => {
                        s.user = storedUser;
                    });
                } else {
                    console.log("No user found in storage.");
                }

                setIsStorageReady(true);
            } catch (error) {
                console.error("Failed to initialize storage");
            }
        };

        initializeStorage();
    }, []);

    if (!isStorageReady) {
        return <div>Loading...</div>;
    }

    return (
        <div id="app-container">
            <IonApp>
                <IonSplitPane when="xs" contentId="main">
                    <Menu />
                    <IonReactRouter>
                        <IonRouterOutlet id="main">
                            <Route path="/login" exact={true}>
                                <Login />
                            </Route>
                            <Route path="/signup" exact={true}>
                                <Signup />
                            </Route>
                            <ProtectedRoute path="/rentals" exact={true}>
                                <UserRentals />
                            </ProtectedRoute>
                            <Route path="/begin" exact={true}>
                                <Home />
                            </Route>
                            <Route path="/logout" exact={true}>
                                <Redirect to="/begin" />
                            </Route>
                            <Route path="/" exact={true}>
                                {!user ? <Redirect to="/begin" /> : <UserRentals />}
                            </Route>
                            <ProtectedRoute path="/settings" exact={true}>
                                <Settings />
                            </ProtectedRoute>
                        </IonRouterOutlet>
                    </IonReactRouter>
                </IonSplitPane>

                <ToastContainer />
            </IonApp>
        </div>
    );
};

export default App;