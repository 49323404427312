import {
    IonPage,
    IonItem,
    IonContent,
    IonList,
    IonLabel,
    IonInput, IonButton
} from '@ionic/react';
import React, { useState } from 'react';
import { saveSeamApiKey } from '../store/firebaseService';
import { Header } from "../components/Header";
import './UserRentals.module.css';
import styles from './UserRentals.module.css'
import Store from "../store";
import {getCurrentUser} from "../store/selectors";

const Settings = () => {
    const [apiKey, setApiKey] = useState('');
    const user = Store.useState(getCurrentUser);

    const handleApiKeyChange = async (value: string | null  |  undefined) => {
        if (value){
            setApiKey(value);
            if (user) {
                try {
                    await saveSeamApiKey(user.uid, value);
                    console.log('SEAM API Key saved successfully!');
                } catch (error) {
                    console.error('Error saving SEAM API Key:', error);
                }
            }
        }
    };

    return (
        <IonPage>
            <Header text="Settings" areNotificationsEnabled={false} setShowNotificationsProps={() => {}} />
            <IonContent>
                <IonList>
                    {/* SEAM API Key Input */}
                    <IonItem style={{margin: '0 34%', fontSize: '1.2rem'}}>
                        <IonLabel position="stacked">SEAM API Key</IonLabel>
                        <IonInput
                            type="text"
                            value={apiKey}
                            placeholder="Enter your SEAM API Key"
                            onIonChange={e => handleApiKeyChange(e.detail.value)}
                        />
                    </IonItem>
                </IonList>
            </IonContent>
        </IonPage>
    );
};

export default Settings;