import {IonInput, IonLabel} from "@ionic/react";
import {Component} from "react";

class CustomField extends Component<{ field: any, errors: any }> {
    render() {
        let {field, errors} = this.props;

        const error = errors && errors.filter((e: { id: any; }) => e.id === field.id)[0];
        const errorMessage = error && errors.filter(function (e: { id: any; }) {
            return e.id === field.id;
        })[0].message;

        return (

            <div className="field">
                <IonLabel className="fieldLabel">
                    {field.label}
                    {error && <p className="fieldp animate__animated animate__bounceIn">{errorMessage}</p>}
                </IonLabel>
                <IonInput className="customInput" {...field.input.props} {...field.input.state} />
            </div>
        );
    }
}

export default CustomField;
