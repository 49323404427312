import pubSub from "../../../events/PubSub";
import ToastProps from './ToastProps';

import {useEffect, useState} from "react";
import EventToastPropsMapper from "./EventToastPropsMapper";
import EventType from "../../../constants/EventType";
import {IonToast} from "@ionic/react";

const ToastContainer = () => {
    const eventMapper = EventToastPropsMapper.getInstance();
    const defaultToastProps = eventMapper.getToastProps(EventType.TOAST_SUCCESS, "Welcome!");
    const [showToast, setShowToast] = useState(false);
    const [toastProps, setToastProps] = useState<ToastProps>(defaultToastProps);

    let {
        position = "bottom",
        duration = 2000, // TODO check if duration should be required or have another default value (2000)in milliseconds
        message,
        color
    }: ToastProps = toastProps;

    useEffect(() => {
        Array.from(eventMapper.getEventPropsMap().keys())
            .forEach(eventType => pubSub.on(eventType, handleShowToast));

        return () => {
            Array.from(eventMapper.getEventPropsMap().keys())
                .forEach(eventType => pubSub.off(eventType, handleShowToast));

        };
    }, []);


    const handleShowToast = (eventType: EventType, message: string) => {
        const toastProps: ToastProps = eventMapper.getToastProps(eventType, message);
        setShowToast(true);
        setToastProps(toastProps);
    }

    const resetStateSettings : () => void = () => {
        setShowToast(false);
        setToastProps(defaultToastProps);
    }

    return (
        <IonToast
            isOpen={showToast}
            message={message}
            duration={duration}
            onDidDismiss={resetStateSettings}
            color={color}
            position={position}
        />
    );
}
export default ToastContainer;