import {Capacitor} from "@capacitor/core";

export const WaveLogin = () => (

    <svg style={{marginBottom: "-0.5rem"}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path fill="#157994" fillOpacity="1"
              d="M0,288L40,277.3C80,267,160,245,240,224C320,203,400,181,480,176C560,171,640,181,720,181.3C800,181,880,171,960,144C1040,117,1120,75,1200,58.7C1280,43,1360,53,1400,58.7L1440,64L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z"></path>
    </svg>
);

export const WaveMenu = () => (
    <div className="wave">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 700 320">
            <path fill="#1fe0e0" fillOpacity="0.7" d="M0,128L60,112C120,96,240,64,360,69.3C480,75,600,117,720,154.7C840,192,960,224,1080,208C1200,192,1320,128,1380,96L1440,64L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"></path>
            <path fill="#1847c2" fillOpacity="0.96" d="M0,32L18.5,37.3C36.9,43,74,53,111,74.7C147.7,96,185,128,222,165.3C258.5,203,295,245,332,256C369.2,267,406,245,443,218.7C480,192,517,160,554,144C590.8,128,628,128,665,128C701.5,128,738,128,775,122.7C812.3,117,849,107,886,117.3C923.1,128,960,160,997,192C1033.8,224,1071,256,1108,250.7C1144.6,245,1182,203,1218,176C1255.4,149,1292,139,1329,112C1366.2,85,1403,43,1422,21.3L1440,0L1440,320L1421.5,320C1403.1,320,1366,320,1329,320C1292.3,320,1255,320,1218,320C1181.5,320,1145,320,1108,320C1070.8,320,1034,320,997,320C960,320,923,320,886,320C849.2,320,812,320,775,320C738.5,320,702,320,665,320C627.7,320,591,320,554,320C516.9,320,480,320,443,320C406.2,320,369,320,332,320C295.4,320,258,320,222,320C184.6,320,148,320,111,320C73.8,320,37,320,18,320L0,320Z"></path>
            <path fill="#0099ff" fillOpacity="0.9" d="M0,224L48,192C96,160,192,96,288,85.3C384,75,480,117,576,149.3C672,181,768,203,864,176C960,149,1056,75,1152,58.7C1248,43,1344,85,1392,106.7L1440,128L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path>
        </svg>

    </div>

)

export const WaveMenuUp = () => {
    if (Capacitor.getPlatform() === "web") {
        return (<div className="wave">

            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 700 320">
                <path fill="#52dcf3" fillOpacity="0.6"
                      d="M0,192L12,213.3C24,235,48,277,72,261.3C96,245,120,171,144,133.3C168,96,192,96,216,122.7C240,149,264,203,288,218.7C312,235,336,213,360,176C384,139,408,85,432,80C456,75,480,117,504,138.7C528,160,552,160,576,165.3C600,171,624,181,648,208C672,235,696,277,720,266.7C744,256,768,192,792,138.7C816,85,840,43,864,58.7C888,75,912,149,936,165.3C960,181,984,139,1008,144C1032,149,1056,203,1080,224C1104,245,1128,235,1152,192C1176,149,1200,75,1224,37.3C1248,0,1272,0,1296,21.3C1320,43,1344,85,1368,128C1392,171,1416,213,1428,234.7L1440,256L1440,0L1428,0C1416,0,1392,0,1368,0C1344,0,1320,0,1296,0C1272,0,1248,0,1224,0C1200,0,1176,0,1152,0C1128,0,1104,0,1080,0C1056,0,1032,0,1008,0C984,0,960,0,936,0C912,0,888,0,864,0C840,0,816,0,792,0C768,0,744,0,720,0C696,0,672,0,648,0C624,0,600,0,576,0C552,0,528,0,504,0C480,0,456,0,432,0C408,0,384,0,360,0C336,0,312,0,288,0C264,0,240,0,216,0C192,0,168,0,144,0C120,0,96,0,72,0C48,0,24,0,12,0L0,0Z"></path>
            </svg>
        </div>)
    }
    else {
        return (<div></div>)
    }

}
