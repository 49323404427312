import {
    IonButton,
    IonCardTitle,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonImg,
    IonPage,
    IonRouterLink,
    IonRow, IonSplitPane
} from '@ionic/react';
import {Action} from '../components/Action';
import './Home.css'
import React from "react";

const Home = () => {

    return (
        <IonPage className="homePage">
            <div className="header">
                <IonImg className="imgbanner" src="/assets/splash.png"/>
            </div>
            <IonContent fullscreen>

                <div className="dotted">
                    <IonGrid>
                        <IonRow className={`ion-text-center ion-justify-content-center heading`}>
                            <IonCol size="11">
                                <div className="title-container">
                                    <IonCardTitle className="title">Connect any lock to Airbnb...</IonCardTitle>
                                    <IonCardTitle className="title">Manage your rentals with ease</IonCardTitle>
                                </div>
                            </IonCol>
                        </IonRow>

                        <IonRow className={`ion-text-center ion-justify-content-center`}>
                            <IonRouterLink routerLink="/signup" className="custom-link">
                            <IonCol size="11">
                                    <IonButton className={` custom-button getStartedButton`}>
                                        <p className="text-button">Get
                                            started &rarr;</p></IonButton>
                                </IonCol>
                            </IonRouterLink>
                        </IonRow>
                    </IonGrid>
                </div>
            </IonContent>

            <IonFooter className="footer">
                <IonGrid>
                    <Action message="Already got an account?" text="Login" link="/login"/>
                    <Action message="Go to feed" text="Go To Feed" link="/feed"/>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default Home;
