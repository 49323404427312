import Store from '.';
import { List, ListItem, Settings, StoreProps, UserFields } from "../typings/typings";
import { loginWithEmail, logout, registerWithEmail, signInWithGooglePopUp } from "./firebaseService";
import storageService from './storage'; // Import the storage service

export const setNotificationsEnabled = (open: boolean) => {
    Store.update((s: StoreProps) => {
        s.settings.enableNotifications = open;
    });
};

export const setSettings = (settings: Settings) => {
    Store.update((s: StoreProps) => {
        s.settings = settings;
    });
};

export const logIn = async (userFields: UserFields) => {
    const user = await loginWithEmail(userFields.email, userFields.password);
    Store.update((s: StoreProps) => {
        s.user = user;
    });
    await storageService.set('user', user);
};

export const logInWithGoogle = async () => {
    const user = await signInWithGooglePopUp();
    Store.update((s: StoreProps) => {
        s.user = user;
    });
    await storageService.set('user', user);
};

export const signUp = async (userFields: UserFields) => {
    const user = await registerWithEmail(userFields.email, userFields.password);
    Store.update((s: StoreProps) => {
        s.user = user;
    });

    await storageService.set('user', user);
};

export const logOut = async () => {
    await logout();
    Store.update((s: StoreProps) => {
        s.user = undefined;
    });
    await storageService.remove('user');
};

// App-specific actions

export const setDone = (list: List, item: ListItem, done: boolean) => {
    Store.update((s, o) => {
        const listIndex = o.lists.findIndex((l: List) => l === list);
        const itemIndex = o.lists[listIndex].items.findIndex((i: ListItem) => i === item);
        s.lists[listIndex].items[itemIndex].done = done;
        if (list === o.selectedList) {
            s.selectedList = s.lists[listIndex];
        }
    });
};
