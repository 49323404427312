import { CardProps } from "../typings/typings";
import React, { Component } from "react";
import Card, { CardContent } from "./Card";
import { IonButton, IonIcon } from "@ionic/react";
import { removeSharp, pencilSharp} from "ionicons/icons";

interface CardContainerProps {
    cards: CardProps[]
}

const CardContainer = (props: CardContainerProps) => {
    return (<>{props.cards.map((card, index) => <FeedCard {...card} key={index} />)}</>)
}
export default CardContainer

class FeedCard extends Component<CardProps> {


    render() {
        let { title, description,onEditHandler, onDeleteHandler, handleOnClick } = this.props;
        // @ts-ignore
        return (
            <Card className="my-4 mx-auto">
                {onDeleteHandler &&
                    <IonButton
                        className="absolute top-0 right-0 mt-2 mr-2 z-50 bg-transparent text-white rounded-full"
                        onClick={(e) => {
                            e.stopPropagation(); // Prevent triggering onClickHandler when delete button is clicked
                            if (onDeleteHandler) {
                                onDeleteHandler();
                            }
                        }}
                    >
                        <IonIcon icon={removeSharp}></IonIcon>
                    </IonButton>
                }

                <IonButton
                    className="absolute bottom-0 right-0 mb-2 mr-2 z-50 bg-transparent text-white rounded-full"
                    onClick={(e) => {
                        e.stopPropagation();
                        if (onEditHandler) {
                            onEditHandler();
                        }
                    }}
                >
                    <IonIcon icon={pencilSharp}></IonIcon>
                </IonButton>
                <CardContent
                    src={""}
                    type={"Lock"}
                    title={title}
                    text={description}
                    src1={"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSuUX5RHVKmAsdZ982ljZd8zj5i5ssw4EcrTw&s"}
                    author={"Apartment Complex"}
                    onClick={handleOnClick}
                >
                </CardContent>
            </Card>
        );
    }
}
