import {
    IonPage,
    IonItem,
    IonContent,
    IonList,
    IonLabel,
    IonInput,
    IonButton,
    IonSegment,
    IonSegmentButton,
    IonToolbar,
    IonTitle,
    IonHeader,
} from '@ionic/react';
import {useState, useEffect} from 'react';
import {Rental, RentalCreate} from '../typings/typings';

interface RentalWizardProps {
    onClose: () => void;
    onSave: (rentalData: RentalCreate | Rental) => void;
    rental: Rental | null;
}

const RentalWizard: React.FC<RentalWizardProps> = ({onClose, onSave, rental}) => {
    const [step, setStep] = useState('details');
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [calendarUrl, setCalendarUrl] = useState('');
    const [seamId, setSeamId] = useState('');
    const [checkInTime, setCheckInTime] = useState('15:00');
    const [checkOutTime, setCheckOutTime] = useState('11:00');

    useEffect(() => {
        if (rental) {
            setTitle(rental.title || '');
            setDescription(rental.description || '');
            setCalendarUrl(rental.calendarUrl || '');
            setSeamId(rental.seamId || '');
            setCheckInTime(rental.checkInTime || '15:00');
            setCheckOutTime(rental.checkOutTime || '11:00');
        }
    }, [rental]);

    const handleNext = () => {
        if (step === 'details') setStep('calendar');
        else if (step === 'calendar') setStep('seam');
        else if (step === 'seam') setStep('times');
    };

    const handleSave = () => {
        if (rental) {
            const rentalData: Rental = {
                id: rental.id,
                title,
                description,
                calendarUrl,
                seamId,
                checkInTime,
                checkOutTime,
            };
            onSave(rentalData);
        } else {
            const rentalData: RentalCreate = {
                title,
                description,
                calendarUrl,
                seamId,
                checkInTime,
                checkOutTime,
            };
            onSave(rentalData);
        }
    };

    const isNextDisabled = () => {
        if (step === 'details') return !title.trim() || !description.trim();
        if (step === 'calendar') return !calendarUrl.trim();
        if (step === 'seam') return !seamId.trim();
        return step === 'times'; // Disable Next on the last step
    };

    const isSaveDisabled = () => {
        return !(
            title.trim() &&
            description.trim() &&
            calendarUrl.trim() &&
            seamId.trim() &&
            checkInTime.trim() &&
            checkOutTime.trim()
        );
    };

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>{rental ? 'Edit Rental' : 'Add Rental'}</IonTitle>
                    <IonButton slot="end" onClick={onClose}>
                        Close
                    </IonButton>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonSegment value={step} onIonChange={(e) => setStep(String(e.detail.value))}>
                    <IonSegmentButton value="details">
                        <IonLabel>Details</IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton value="calendar">
                        <IonLabel>Calendar</IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton value="seam">
                        <IonLabel>SEAM ID</IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton value="times">
                        <IonLabel>Check-In/Out</IonLabel>
                    </IonSegmentButton>
                </IonSegment>

                <IonList>
                    {step === 'details' && (
                        <>
                            <IonItem>
                                <IonLabel position="stacked">Title</IonLabel>
                                <IonInput
                                    type="text"
                                    value={title}
                                    placeholder="Enter a title for the rental"
                                    onIonChange={(e) => setTitle(String(e.target.value || ''))}
                                />
                            </IonItem>
                            <IonItem>
                                <IonLabel position="stacked">Description</IonLabel>
                                <IonInput
                                    type="text"
                                    value={description}
                                    placeholder="Enter a description for the rental"
                                    onIonChange={(e) => setDescription(String(e.target.value || ''))}
                                />
                            </IonItem>
                        </>
                    )}

                    {step === 'calendar' && (
                        <IonItem>
                            <IonLabel position="stacked">Calendar URL</IonLabel>
                            <IonInput
                                type="url"
                                value={calendarUrl}
                                placeholder="Enter your Calendar URL"
                                onIonChange={(e) => setCalendarUrl(String(e.target.value || ''))}
                            />
                        </IonItem>
                    )}

                    {step === 'seam' && (
                        <IonItem>
                            <IonLabel position="stacked">SEAM Lock ID</IonLabel>
                            <IonInput
                                type="text"
                                value={seamId}
                                placeholder="Enter your SEAM Lock ID"
                                onIonChange={(e) => setSeamId(String(e.target.value || ''))}
                            />
                        </IonItem>
                    )}

                    {step === 'times' && (
                        <>
                            <IonItem>
                                <IonLabel position="stacked">Default Check-In Time</IonLabel>
                                <IonInput
                                    type="time"
                                    value={checkInTime}
                                    onIonChange={(e) => setCheckInTime(String(e.target.value || ''))}
                                />
                            </IonItem>
                            <IonItem>
                                <IonLabel position="stacked">Default Check-Out Time</IonLabel>
                                <IonInput
                                    type="time"
                                    value={checkOutTime}
                                    onIonChange={(e) => setCheckOutTime(String(e.target.value || ''))}
                                />
                            </IonItem>
                        </>
                    )}
                </IonList>

                {step !== 'times' && (
                    <IonButton expand="block" onClick={handleNext} disabled={isNextDisabled()}>
                        Next
                    </IonButton>
                )}

                {step === 'times' && (
                    <IonButton expand="block" onClick={handleSave} disabled={isSaveDisabled()}>
                        {rental ? 'Update Rental' : 'Save Rental'}
                    </IonButton>
                )}
            </IonContent>
        </IonPage>
    );
};

export default RentalWizard;