import {IonCol, IonRouterLink, IonRow} from "@ionic/react";
import {Component} from "react";

export class Action extends Component<{ message: string, link: string, text: string }> {
    render() {
        return (

            <IonRow className="ion-text-center ion-justify-content-center">
                <IonCol size="12">
                    <p>
                        {this.props.message}
                        <IonRouterLink className="custom-link"
                                       routerLink={this.props.link}> {this.props.text} &rarr;</IonRouterLink>
                    </p>
                </IonCol>
            </IonRow>
        );
    }
}
