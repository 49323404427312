import { createSelector } from "reselect";
import { StoreProps } from "../typings/typings";
import storageService from './storage';

const getState = (state: StoreProps) => state;

export const getLists = createSelector(getState, (state) => state.lists);
export const getCurrentUser = createSelector(getState, (state) => state.user);
export const getNotifications = createSelector(getState, (state) => state.notifications);

export const getSettings = createSelector(getState, (state) => {
    // Synchronously retrieve the user from storage if possible
    let user;
    storageService.get('user').then(storedUser => {
        if (storedUser) {
            console.log("User retrieved from storage:");
        } else {
            console.log("No user found in storage, using user from state.");
        }
        user = storedUser || state.user;
    }).catch(error => {
        console.error("Failed to retrieve user from storage:");
        user = state.user;
    });

    const settings = {
        ...state.settings,
        user: user,
    };

    console.log("Returning settings:");

    return settings;
});