import classNames from 'classnames';
import React, {Component} from "react";
import {IonButton, IonIcon} from "@ionic/react";
import './Card.css'
import {play, removeSharp } from 'ionicons/icons';

interface CardProps {
    children?: any,
    className: any,

    onClickHandler?: () => {},
    innerClassName?: any


}

export class Card extends Component<CardProps> {
    render() {
        let {children, className, onClickHandler, ...props} = this.props;
        return (
            <div onClick={onClickHandler} {...props} className={classNames('max-w-xl  relative', className)}>
                <div
                    className={"bg-white shadow-md rounded-b-xl dark:bg-black " + props.innerClassName}>{children}</div>
            </div>
        );
    }
}


export class CardTextContent extends Component<{ type: string, title: string, text: string, src: string, author: string, buttonHandler?: (l: any) => void }> {
    render() {
        return <div className="px-4 py-4 bg-white rounded-b-xl dark:bg-gray-900">
            <h4 className="font-bold py-0 text-s text-gray-400 dark:text-gray-500 uppercase">{this.props.type}</h4>
            <h2 className="font-bold text-2xl text-gray-800 dark:text-gray-100">{this.props.title}</h2>
            <p className="sm:text-sm text-s text-gray-500 mr-1 my-3 dark:text-gray-400">{this.props.text}</p>
            <div className="flex items-center space-x-10 ">
                <div className="flex items-center space-x-4">
                    <div className="w-10 h-10 relative">
                        <img src={this.props.src}
                             className="rounded-full object-cover min-w-full min-h-full max-w-full max-h-full"
                             alt=""/>

                    </div>
                    <h3 className="text-gray-500 dark:text-gray-200 m-l-8 text-sm font-medium">{this.props.author}</h3>
                </div>
                {this.props.buttonHandler &&
                <IonButton color="primary" onClick={this.props.buttonHandler} className="play-overlay">
                  Next
                  <IonIcon slot="end" icon={play}></IonIcon>
                </IonButton>}
            </div>
        </div>;
    }
}


export class CardImageContent extends Component<{ src: string }> {
    render() {
        return <div className="h-32 w-full relative">
            <img className="rounded-t-xl object-cover min-w-full min-h-full max-w-full max-h-full"
                 src={this.props.src}
                 alt=""/>
        </div>;
    }
}

interface CardPreviewProps {
    children: any;
    src: string;
    type: string;
    title: string;
    text: string;
    src1: string;
    author: string
    buttonHandler: () => void;
}


interface CardContentProps {
    children: any;
    src: string;
    type: string;
    title: string;
    text: string;
    src1: string;
    author: string;
    onClick: () => void;
}


export class CardPreviewContent extends Component<CardPreviewProps> {
    render() {
        return <>
            <CardImageContent src={this.props.src}/>
            <CardTextContent type={this.props.type} title={this.props.title} text={this.props.text}
                             src={this.props.src1} author={this.props.author} buttonHandler={this.props.buttonHandler}/>
            {this.props.children}
        </>;
    }
}

export class CardContent extends Component<CardContentProps> {
    render() {
        return <div onClick={this.props.onClick}>
            <CardTextContent type={this.props.type} title={this.props.title} text={this.props.text}
                             src={this.props.src1} author={this.props.author} buttonHandler={undefined}/>
            {this.props.children}
        </div>;
    }
}

export default Card;
