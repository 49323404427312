export const notifications = [
  { title: "New friend request", when: "6 hr" },
  { title: "Please change your password", when: "1 day" },
  { title: "You have a new message", when: "2 weeks" },
  { title: "Welcome to the app!", when: "1 month" },
];

// Some fake lists
export const lists = [
  {
    name: "Groceries",
    id: "groceries",
    items: [
      { name: "Apples" },
      { name: "Bananas" },
      { name: "Milk" },
      { name: "Ice Cream" },
    ],
  },
  {
    name: "Hardware Store",
    id: "hardware",
    items: [
      { name: "Circular Saw" },
      { name: "Tack Cloth" },
      { name: "Drywall" },
      { name: "Router" },
    ],
  },
  {
    name: "Work",
    id: "work",
    items: [{ name: "TPS Report" }, { name: "Set up email" }],
  },
  { name: "Reminders", id: "reminders" },
];
