import { Store as PullStateStore } from "pullstate";

import { lists, notifications } from "../mock";
import { Settings, StoreProps } from "../typings/typings";

const Store = new PullStateStore({
  notificationsOpen: false,
  user: undefined,
  lists,
  notifications,
  settings: {
    enableNotifications: true,
  } as Settings,
} as StoreProps);

export default Store;
