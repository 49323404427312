// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";
import {
    createUserWithEmailAndPassword,
    getAuth,
    GoogleAuthProvider,
    onAuthStateChanged,
    signInWithEmailAndPassword,
    signInWithPopup,
    signOut,
    User,
} from "firebase/auth";
import {
    collection,
    DocumentData,
    getDocs,
    deleteDoc,
    addDoc,
    updateDoc,
    doc,
    getDoc,
    getFirestore,
    query,
    where,
} from "firebase/firestore";
import {GoogleAuth} from "@codetrix-studio/capacitor-google-auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
    apiKey:
        process.env.REACT_APP_API_KEY || "AIzaSyCBK7EfF0Z97O-4ni1fmsYB4j8HvG0HkkY",
    authDomain: process.env.REACT_APP_AUTH_DOMAIN || "expanse-01.firebaseapp.com",
    projectId: process.env.REACT_APP_PROJECT_ID || "expanse-01",
    storageBucket:
        process.env.REACT_APP_STORAGE_BUCKET || "expanse-01.appspot.com",
    messagingSenderId:
        process.env.REACT_APP_MESSAGING_SENDER_ID || "683424249890",
    appId:
        process.env.REACT_APP_APP_ID || "1:683424249890:web:94692e5a32e2399b3c327b",
    measurementId: process.env.REACT_APP_MEASUREMENT_ID || "G-VKQCDRDVG0",
};
// use hook after platform dom ready
GoogleAuth.initialize({
    clientId:
        "683424249890-fu9hi4bmupt2bh8e279j3dn7bp5no4sv.apps.googleusercontent.com",
});
// Initialize Firebase
const app = initializeApp(firebaseConfig);
getAnalytics(app);
const firestore = getFirestore(app);
const auth = getAuth(app);
export const provider = new GoogleAuthProvider();

export async function signInWithGooglePopUp(): Promise<User | undefined> {
    let user = undefined;
    try {
        const credential = await signInWithPopup(auth, provider);
        user = credential.user;
    } catch (error) {
        console.log(error);
    }
    return user;
}

export async function loginWithEmail(
    email: string,
    password: string
): Promise<User | undefined> {
    let user = undefined;
    try {
        let credential = await signInWithEmailAndPassword(auth, email, password);
        user = credential.user;
    } catch (error) {
        console.log(error);
    }
    return user;
}

export async function onAuthStateChange(callback: (user: User | null) => void) {
    return onAuthStateChanged(auth, callback);
}

export async function registerWithEmail(
    email: string,
    password: string
): Promise<User | undefined> {
    let user = undefined;
    try {
        let credential = await createUserWithEmailAndPassword(
            auth,
            email,
            password
        );
        user = credential.user;
    } catch (error) {
        console.log(error);
    }
    return user;
}

export async function logout(): Promise<void> {
    await signOut(auth);
    // User is signed out
}

export async function getID(
    collectionName: string,
    id: string
): Promise<DocumentData | null> {
    const documentRef = doc(firestore, collectionName, id);
    const documentSnapshot = await getDoc(documentRef);

    if (!documentSnapshot.exists()) {
        return null;
    }

    const data = documentSnapshot.data();
    data.id = documentSnapshot.id;
    return data;
}

export async function getAllDocs(
    collectionName: string
): Promise<Array<DocumentData>> {
    const snapshot = await getDocs(collection(firestore, collectionName));
    return snapshot.docs.map((doc) => {
        const data = doc.data();
        data.id = doc.id;
        return data;
    });
}

/**
 * Saves a document to any Firestore collection.
 *
 * @param {string} collectionName - The name of the Firestore collection.
 * @param {Object} data - The document data to store.
 * @returns {Promise<string>} - The document ID of the newly created document.
 */
export async function saveDocument(collectionName: string, data: any) {
    try {
        const docRef = await addDoc(collection(firestore, collectionName), data);
        return docRef.id;
    } catch (error) {
        console.error("Error adding document to Firestore:", error);
        throw error;
    }
}

/**
 * Updates a document in any Firestore collection.
 *
 * @param {string} collectionName - The name of the Firestore collection.
 * @param {string} docId - The ID of the document to update.
 * @param {Object} data - The updated document data.
 * @returns {Promise<void>} - Resolves when the document is successfully updated.
 */
export async function updateDocument(collectionName: string, docId: string, data: any) {
    try {
        const docRef = doc(firestore, collectionName, docId);
        await updateDoc(docRef, data);
    } catch (error) {
        console.error("Error updating document in Firestore:", error);
        throw error;
    }
}

export async function shareDocument(
    collectionName: string,
    docId: string
): Promise<void> {
    await updateDoc(doc(firestore, collectionName, docId), {shared: true});
}

export async function deleteDocument(
    collectionName: string,
    docId: string
): Promise<void> {
    await deleteDoc(doc(firestore, collectionName, docId));
}

export async function getUserDocs(
    collectionName: string,
    uid: string = ""
): Promise<Array<DocumentData>> {
    // Si filtramos docs por el uid, usar esto:
    const snapshot = await getDocs(
        query(collection(firestore, collectionName), where("uid", "==", uid))
    );
    return snapshot.docs.map((doc) => {
        const data = doc.data();
        data.id = doc.id;
        return data;
    });
}

export async function saveSeamApiKey(userId: string, apiKey: string) {
    const docRef = doc(firestore, 'seamKeys', userId);
    await updateDoc(docRef, { seamApiKey: apiKey });
}

export async function getSharedDocs(
    collectionName: string,
): Promise<Array<DocumentData>> {
    const sharedQuery = query(collection(firestore, collectionName), where("shared", "==", true));
    const sharedQuerySnapshot = await getDocs(sharedQuery);

    return sharedQuerySnapshot.docs.map(doc => ({id: doc.id, ...doc.data()}));
}

