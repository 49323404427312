import {SetStateAction, useState} from "react";
import {ErrorMsg, InputState} from "../typings/typings";

export const useFormInput = (initialValue: string = ""): InputState => {
    const [value, setValue] = useState(initialValue);

    const handleChange = async (e: { currentTarget: { value: any } }) => {
        const tempValue = await e.currentTarget.value;
        setValue(tempValue);
    };

    return {
        value,
        reset: (newValue: SetStateAction<string>) => setValue(newValue),
        onIonChange: handleChange,
        onKeyUp: handleChange,
    };
};

export const validateForm = (fields: any[]) => {

    let errors: ErrorMsg[] = [];

    fields.forEach(field => {

        if (field.required) {

            const fieldValue = field.input.state.value;

            if (fieldValue === "") {

                const error = {

                    id: field.id,
                    message: `Please check your ${field.id}`,
                };

                errors.push(error);
            }
        }
    });

    return errors;
}
