import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonCardTitle,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonPage,
    IonRow,
    IonToolbar
} from '@ionic/react';

import {arrowBack, helpOutline} from "ionicons/icons";
import CustomField from '../components/CustomField';
import {mapFieldsToUserFields, useLoginFields} from '../data/fields';
import {Action} from '../components/Action';
import {WaveLogin} from '../components/Wave';
import {useState} from 'react';
import {validateForm} from '../data/utils';
import {ErrorMsg} from "../typings/typings";
import {Keyboard} from '@capacitor/keyboard';

import {Capacitor} from "@capacitor/core";
import {logIn, logInWithGoogle} from "../store/actions";
import {useHistory} from 'react-router-dom';
import {debounce} from "lodash";
import {GoogleLoginButton} from 'react-social-login-buttons';
import './Login.module.css';
import styles from './Login.module.css'
import GoogleButton from "../components/GoogleButton";
import pubSub from "../events/PubSub";
import EventType from "../constants/EventType";


const Login = () => {

    const history = useHistory();
    const fields = useLoginFields();
    const init: ErrorMsg[] = []
    const [errors, setErrors] = useState(init);
    const [showFooter, setShowFooter] = useState(true)
    if (Capacitor.getPlatform() !== "web") {
        Keyboard.addListener('keyboardWillShow', info => {
            setShowFooter(false)
        });

        Keyboard.addListener('keyboardWillHide', () => {
            setShowFooter(true)
        });
    }
    const login = () => {

        const errors = validateForm(fields);
        setErrors(errors);

        if (!errors.length) {
            logIn(mapFieldsToUserFields(fields)).then(() => {
                history.push("/");
            }).catch(error =>
                pubSub.emit(EventType.TOAST_ERROR, EventType.TOAST_ERROR, "Error: " + error));
        }
    }
    const debouncedLogin = debounce(login, 500);
    const handleLogin = () => {
        debouncedLogin();
    };


    const keypress = (event: React.KeyboardEvent<HTMLIonButtonElement>) => {
        if (event.key === 'Enter') {
            handleLogin()
        }
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton icon={arrowBack} text="" className="custom-back"/>
                    </IonButtons>
                    <IonButtons slot="end">
                        <IonButton>
                            <IonIcon icon={helpOutline}/>
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <div className="dotted">
                    <IonGrid className="ion-padding">
                        <IonRow>
                            <IonCol size="12">
                                <IonCardTitle>Log in</IonCardTitle>
                                <h5 className="headingTextLogin"> Welcome back, hope you're doing well</h5>
                            </IonCol>
                        </IonRow>

                        <IonRow onKeyDown={keypress} className="ion-margin-top ion-padding-top">
                            <IonCol size="12">

                                {fields.map((field, key) => {

                                    return <CustomField field={field} key={key} errors={errors}/>;
                                })}

                                <IonButton className="custom-button" expand="block" onClick={handleLogin}>


                                    <p className="text-button">Login</p></IonButton>
                            </IonCol>

                        </IonRow>
                        <GoogleButton />
                    </IonGrid>
                </div>
            </IonContent>

            <IonFooter className="footer-wave" hidden={!showFooter}>
                <IonGrid className="ion-no-margin ion-no-padding">
                    <Action message="Don't have an account?" text="Sign up" link="/signup"/>
                    <WaveLogin/>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default Login;
