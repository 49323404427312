import ToastProps from "./ToastProps";
import EventType from "../../../constants/EventType";

class EventToastPropsMapper {
    private static instance : EventToastPropsMapper;
    private eventPropsMap = new Map<EventType, (message:string) => ToastProps>();

    private constructor() {
        this.eventPropsMap.set(EventType.TOAST_ERROR, this.getErrorToastPropsGetter());
        this.eventPropsMap.set(EventType.TOAST_SUCCESS, this.getSuccessToastPropsGetter());
        this.eventPropsMap.set(EventType.TOAST_INFO, this.getInfoToastPropsGetter());
    }

    public static getInstance() : EventToastPropsMapper {
       if(!EventToastPropsMapper.instance) {
           EventToastPropsMapper.instance = new EventToastPropsMapper();
       }
       return EventToastPropsMapper.instance;
    }

    public getToastProps(eventType: EventType, message: string) : ToastProps {
         let getToastPropsGetter : ((message:string) => ToastProps) | undefined =  this.eventPropsMap.get(eventType);
         if(!getToastPropsGetter) {
             getToastPropsGetter = this.getErrorToastPropsGetter();
             message = "An error has occurred";
         }
         return getToastPropsGetter(message);
    }

    public getEventPropsMap() : Map<EventType, (message:string) => ToastProps> {
        return this.eventPropsMap;
    }

    private getErrorToastPropsGetter() : (message: string) => ToastProps {
        return  (message): ToastProps =>  {
            return {
                color: "danger",
                message: message,
                position: "bottom"
            }
        }
    }

    private getSuccessToastPropsGetter() : (message: string) => ToastProps {
        return  (message): ToastProps =>  {
            return {
                color: "success",
                message: message,
                position: "bottom"
            }
        }
    }

    private getInfoToastPropsGetter() : (message: string) => ToastProps {
        return  (message): ToastProps =>  {
            return {
                color: "medium",
                message: message,
                position: "bottom"
            }
        }
    }
}

export default EventToastPropsMapper;