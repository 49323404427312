import {useFormInput} from "./utils";
import {Field, FieldDictionary, UserFields} from "../typings/typings";

export const useSignupFields = (): Field[] => {
    return [
     /*   {
            id: "first_name",
            label: "First Name",
            required: true,
            input: {
                props: {
                    type: "text",
                    placeholder: "Joe Bloggs",
                },
                state: useFormInput(""),
            },
        },
        {
            id: "last_name",
            label: "Last Name",
            required: true,
            input: {
                props: {
                    type: "text",
                    placeholder: "Joe Bloggs",
                },
                state: useFormInput(""),
            },
        },*/
        {
            id: "username",
            label: "Email",
            required: true,
            input: {
                props: {
                    type: "email",
                    placeholder: "joe@bloggs.com",
                },
                state: useFormInput(""),
            },
        },
        {
            id: "password",
            label: "Password",
            required: true,
            input: {
                props: {
                    type: "password",
                    placeholder: "*********",
                },
                state: useFormInput(""),
            },
        },
    ];
};

export const mapFieldsToUserFields = (fieldArray: Field[]): UserFields => {
    const fields:FieldDictionary = fieldArray.reduce((dictionary, field) => {
        dictionary[field.id] = field;
        return dictionary;
    }, {} as FieldDictionary);
    const email = fields["username"].input.state.value;
    const password = fields["password"].input.state.value;
    return { email, password };
};
export const useLoginFields = () => {

    return [

        {
            id: "username",
            label: "Email",
            required: true,
            input: {

                props: {
                    type: "email",
                    placeholder: "joe@bloggs.com"
                },
                state: useFormInput("")
            }
        },
        {
            id: "password",
            label: "Password",
            required: true,
            input: {

                props: {
                    type: "password",
                    placeholder: "*******"
                },
                state: useFormInput("")
            }
        }
    ];
}
