import { Storage } from '@ionic/storage';

class StorageService {
    private storage: Storage;

    constructor() {
        this.storage = new Storage();
        console.log("StorageService: Initialized storage instance.");
    }

    async init() {
        try {
            await this.storage.create();
            console.log("StorageService: Storage created successfully.");
        } catch (error) {
            console.error("StorageService: Failed to create storage.", error);
        }
    }

    async get(key: string) {
        try {
            const value = await this.storage.get(key);
            console.log(`StorageService: Retrieved value for key '${key}':`);

            try {
                // Try to parse the JSON if it's a stringified object
                const parsedValue = JSON.parse(value);
                console.log(`StorageService: Parsed value for key '${key}':`);
                return parsedValue;
            } catch (error) {
                // If parsing fails, return the value as is (e.g., it's a simple type)
                console.log(`StorageService: Value for key '${key}' is not a JSON object. Returning raw value.`);
                return value;
            }
        } catch (error) {
            console.error(`StorageService: Failed to retrieve value for key '${key}'.`, error);
        }
    }

    async set(key: string, value: any) {
        try {
            // Only stringify objects, arrays, and other complex types
            const serializedValue = typeof value === 'object' && value !== null ? JSON.stringify(value) : value;
            await this.storage.set(key, serializedValue);
            console.log(`StorageService: Successfully set value for key '${key}':`);
        } catch (error) {
            console.error(`StorageService: Failed to serialize and store the data for key '${key}'.`, error);
        }
    }

    async remove(key: string) {
        try {
            await this.storage.remove(key);
            console.log(`StorageService: Successfully removed key '${key}'.`);
        } catch (error) {
            console.error(`StorageService: Failed to remove key '${key}'.`, error);
        }
    }
}

const storageService = new StorageService();
export default storageService;
