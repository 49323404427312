import {useHistory} from "react-router-dom";
import {logInWithGoogle} from "../store/actions";
import {debounce} from "lodash";
import {GoogleLoginButton} from "react-social-login-buttons";

function GoogleButton() {
    const history = useHistory();
    const loginWithGoogle = () => {
        logInWithGoogle().then(() => {
            history.push("/rentals")
        })
    }
    const debouncedLoginGoogle = debounce(loginWithGoogle, 500);
    const handleLoginGoogle = () => {
        debouncedLoginGoogle();
    };
    return <GoogleLoginButton onClick={handleLoginGoogle}/>;
}

export default GoogleButton